<template>
  <div class="box">
    <topMenu :index="0"></topMenu>
    <div class="content">
      <img class="wen1" src="../../src/assets/image/wen1.png" alt="" />
      <img class="wen2" src="../../src/assets/image/wen1.png" alt="" />
      <div style="position: relative">
        <div style="width: 55%; margin: 40px auto 20px; display: flex">
          <span style="font-size: 24px; line-height: 45px; text-align: center"
            >展会概况</span
          >
          <img
            style="margin: 10px; width: 26px; height: 24.5px"
            src="../../src/assets/image/title_right.png"
            alt=""
          />
        </div>
        <div
          style="
            width: 55%;
            margin: auto;
            box-sizing: border-box;
            padding: 30rpx;
            font-size: 16px;
            text-indent: 2em;
            line-height: 40px;
            text-align: left;
            text-align: justify;
          "
        >
          <div v-html="content"></div>
          <!-- <div>
            为深入学习贯彻习近平总书记视察山西重要讲话重要指示精神，由中华人民共和国商务部、科学技术部、
            山西省人民政府主办，中国科学院、中国煤炭工业协会、中国节能协会、国际节能环保协会支持，
            商务部外贸发展事务局、山西省商务厅、省科技厅、省能源局、省投资促进局、太原市人民政府承
            办2022中国（太原）国际能源产业博览会，定于2022年9月1-3日在太原晋阳湖国际会展中心举办。
          </div>
          <div>
            本届博览会以 “科技赋能 减碳增效
            绿色发展”为主题，旨在打造国内外能源交流合作的新平
            台，构建集约、绿色、多元、低碳的中国能源产业体系，提升山西绿色低碳发展水平，加快推
            进能源革命综合改革试点，推进全国能源革命实现新时代能源事业高质量发展。全力打造能源行业领
            域全产业链产品展示、信息共享、技术交流和招商引资的合作平台，以优异成绩迎接党的二十大胜利召开。
          </div> -->
        </div>
        <div style="width: 55%; margin: 40px auto 20px; display: flex">
          <span style="font-size: 24px; line-height: 45px">展区介绍</span>
          <img
            style="margin: 10px; width: 26px; height: 24.5px"
            src="../../src/assets/image/title_right.png"
            alt=""
          />
        </div>
        <div
          style="
            width: 55%;
            margin: auto;
            box-sizing: border-box;
            padding: 30rpx;
            font-size: 16px;
            line-height: 40px;
            text-align: left;
            text-align: justify;
          "
        >
          <div v-html="subtitle"></div>

          <!-- <div>
            【新能源展区】
            主要展示氢能、核能、生物质能、光电、风电、水电等在绿色发展、低碳发展和智慧发展
            中产生的新技术、新设备、新产品和新成果。 
          </div>
          <div>
            【电力展区】主要展示发电、输电、智能电网、工业电气等领域的新技术、新设备、新产品和新成果。
          </div>
          <div>
            【储能展区】主要展示蓄热、蓄冷、蓄氢、重力势能及电容、电感储能等领域的新技术、新设备、新产品和新成果。
          </div>
          <div>
            【5G+能源展区】主要展示5G技术在煤矿智能化建设、智能化工作面、智能化场景扩展领域的新应用、新产品、新技术，数字能源、能源效率、能源质量管理等领域的新技术、新设备、新产品和新成果。
          </div>
          <div>
            【节能减碳展区】
            主要展示“双碳”领域零碳新工业与传统节能改造、先进碳捕集利用与封存技术、数字化智慧低碳管理、温室气体监测技术设备、先进的生物质燃料技术、节能减碳及碳排放交易等绿色低碳发展技术、服务和装备。
          </div> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import topMenu from "@/components/topMenu.vue";
import Footer from "../components/Footer.vue";
import { contentDetail } from "../api/index";
export default {
  components: {
    topMenu,
    Footer,
  },
  data() {
    return {
      state: null,
      id: "",
      content: "",
      subtitle: "",
    };
  },
  methods: {
    getInfo(id) {
      contentDetail(id).then((res) => {
        // console.log("res", res);
        this.content = res.data.content;
        this.subtitle = res.data.news;
      });
    },
  },
  mounted() {
    // console.log("xxxx", this.$route.query);
    if (this.$route.query) {
      this.id = this.$route.query.section;
      this.getInfo(this.id);
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  position: relative;
  margin: 20px auto 50px;

  .wen1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 530px;
    height: 408px;
  }

  .wen2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 610px;
    height: 407px;
  }
}

.con_title {
  width: 55%;
  margin: 40px auto 20px;
  display: flex;

  span {
    font-size: 24px;
    line-height: 45px;
    text-align: center;
  }

  img {
    margin: 10px;
    width: 26px;
    height: 24.5px;
  }
}

.pageText {
  width: 55%;
  margin: auto;
  box-sizing: border-box;
  padding: 30rpx;
  font-size: 20px;
  text-indent: 2em;
  line-height: 40px;
  text-align: left;
  text-align: justify;
}
</style>
